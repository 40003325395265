<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!-- vehicle card design -->
        <h3>{{ $objectDenomination(vehicle) }}</h3>
        <h3>{{ $t("COMMON.ID") }} : {{ vehicle.code }}</h3>

        <p>
          {{ $t("VEHICLES.RESERVE_PRICE") }}:
          {{ $formatCurrency(vehicle.reserve_price) }}
        </p>

        <p>
          {{ $t("BIDS.HIGHEST_BID") }}:
          {{ $formatCurrency(vehicle.highest_bid) }}
        </p>

        <br />
      </div>
    </div>

    <comments :object="negotiationData" sortBy="created_at" />
  </div>
</template>
<script>
import Comments from "@/components/Comments/CommentsComponent.vue";
import { cloneDeep } from "lodash";

export default {
  layout: "DashboardLayout",

  components: {
    Comments,
  },

  mixins: [],

  props: ["vehicle", "negotiation"],

  data() {
    const negotiationData = cloneDeep(this.negotiation);

    negotiationData.organization = negotiationData.sellerOrganization;

    return {
      negotiationData,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {},

  watch: {},
};
</script>
