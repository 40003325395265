<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <!-- vehicle card design -->
        <h3>{{ $objectDenomination(vehicle) }}</h3>

        <p>
          <span>{{ bid.user.firstname }} {{ bid.user.lastname }}</span>
          <span> - </span>
          <span>{{ bid.location.name }}</span>
        </p>

        <p>{{ $t("BIDS.BID") }}: {{ $formatCurrency(bid.bid) }}</p>

        <p>
          {{ $t("VEHICLES.RESERVE_PRICE") }}:
          {{ $formatCurrency(vehicle.reserve_price) }}
        </p>

        <br />
      </div>
    </div>

    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="addBid"
      @keydown.enter.prevent="() => {}"
    >
      <div class="row">
        <div class="col-4">
          <div class="form-group-wrapper">
            <div class="form-group-wrapper-item full">
              <base-input
                :label="$t('COMMON.AMOUNT')"
                :placeholder="$t('COMMON.AMOUNT')"
                v-model="amount"
                required
                :min="vehicle.highest_bid"
                :disabled="loading"
              />
              <validation-error :errors="apiValidationErrors.bid" />
            </div>
          </div>
        </div>

        <div class="col-6">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            native-type="submit"
            :disabled="loading"
          >
            <span class="btn-inner--text">
              {{ $t("VEHICLES.ADD_BID") }}
            </span>
          </base-button>

          <base-button
            class="elite-button black"
            icon
            size="sm"
            @click="closeSale"
            :disabled="loading"
          >
            <span class="btn-inner--icon">
              <i class="far fa-check"></i>
            </span>

            <span class="btn-inner--text">
              {{ $t("VEHICLES.CLOSE_THE_SALE") }}
            </span>
          </base-button>
        </div>
      </div>

      <comments :object="negotiationData" sortBy="created_at" />
    </form>
  </div>
</template>
<script>
import { cloneDeep, isEqual } from "lodash";
import { Option, Select, Table, TableColumn } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Comments from "@/components/Comments/CommentsComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Comments,
  },

  mixins: [formMixin],

  props: ["vehicle", "bid", "negotiation", "formErrors", "loading"],

  data() {
    const negotiationData = cloneDeep(this.negotiation);

    negotiationData.organization = negotiationData.buyerOrganization;
    negotiationData.location = negotiationData.buyerLocation;

    return {
      amount: null,
      negotiationData,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async addBid() {
      this.$emit("addBid", this.amount);
      this.amount = "";
    },
    async closeSale() {
      this.$emit("closeSale", this.amount);
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
