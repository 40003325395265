<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-12">
          <!-- vehicle card design -->
          <h3>{{ $objectDenomination(vehicle) }}</h3>
          <h3>{{ $t("COMMON.ID") }} : {{ vehicle.code }}</h3>

          <p>{{ $formatCurrency(vehicle.highest_bid) }}</p>
          <p>
            {{ $t("VEHICLES.RESERVE_PRICE") }}:
            {{ $formatCurrency(vehicle.reserve_price) }}
          </p>

          <br />

          <h4>{{ $t("VEHICLES.AUCTION_HIGHEST_BIDDERS") }}</h4>
        </div>
      </div>
    </div>

    <br />

    <!-- bids -->
    <div style="padding: 10px; background-color: rgba(243, 244, 246, 1)">
      <div v-for="(bid, index) in bids" :key="bid.id">
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex; gap: 8px">
            <div style="width: 25px; height: 25px; border-radius: 100%">
              {{ index + 1 }}
            </div>

            <div>
              <div>
                <span>{{ bid.user.firstname }} {{ bid.user.lastname }}</span>
                <span> o </span>
                <span>{{ bid.location.name }}</span>
              </div>

              <h3>{{ $formatCurrency(bid.bid) }}</h3>
            </div>
          </div>

          <div>
            <base-button
              class="elite-button btn-black"
              icon
              size="sm"
              @click="negotiateVehicle(bid)"
            >
              <!-- <span class="btn-inner--icon">
                <i class="far fa-redo"></i>
              </span> -->
              <span class="btn-inner--text">
                {{ $t("NEGOTIATIONS.NEGOTIATE") }}
              </span>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vehicle-view-global",

  props: ["vehicle"],

  data() {
    return {};
  },

  created() {},

  computed: {
    bids() {
      return this.vehicle.highestBids
        .filter((obj, index, self) => {
          return index === self.findIndex((t) => t.user.id == obj.user.id);
        })
        .slice(0, 3);
    },
  },

  methods: {
    negotiateVehicle(bid) {
      this.$emit("onNegotiateVehicle", bid);
    },
  },

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
