<template>
  <div class="container-fluid">
    <span
      class="resize-loading"
      v-if="!vehicle || !negotiationParent || !bid || !buyerNegotation"
    >
      <span class="loader"></span>
    </span>

    <negotiation-form
      v-if="vehicle && negotiationParent && bid && buyerNegotation"
      :loading="loading"
      :vehicle="vehicle"
      :bid="bid"
      :negotiation="buyerNegotation"
      :formErrors="formErrors"
      @addBid="addBid"
      @closeSale="closeSale"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultNegotiation from "../defaultNegotiation";
import NegotiationForm from "../partials/NegotiationForm.vue";
import { DISCUTION_TYPE_BUYER } from "@/constants/negotiations";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    NegotiationForm,
  },

  mixins: [alertLeave],

  props: {
    vehicleId: {
      type: [String, Number],
      required: true,
    },
    negotiationParentId: {
      type: [String, Number],
      required: true,
    },
    bidId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      customBidId: this.bidId,
      vehicle: null,
      negotiationParent: null,
      bid: null,
      buyerNegotation: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("vehicles/get", this.vehicleId);
        this.vehicle = this.$store.getters["vehicles/vehicle"];

        await this.$store.dispatch(
          "negotiations/get",
          this.negotiationParentId
        );
        this.negotiationParent =
          this.$store.getters["negotiations/negotiation"];

        await this.$store.dispatch("bids/get", this.customBidId);
        this.bid = this.$store.getters["bids/bid"];

        let params = {
          filter: {
            parent_id: this.negotiationParentId,
            buyer_organization_id: this.bid.location.organization.id,
            buyer_location_id: this.bid.location.id,
          },
          include: "buyerOrganization,buyerLocation",
        };

        await this.$store.dispatch("negotiations/list", params);
        const negotiations = this.$store.getters["negotiations/list"];

        if (negotiations.length > 0) {
          this.buyerNegotation = negotiations[0];
        } else {
          const negotiationData = cloneDeep(defaultNegotiation);

          negotiationData.discution_type = DISCUTION_TYPE_BUYER;
          negotiationData.vehicle.id = this.vehicle.id;
          negotiationData.sellerOrganization.id = this.vehicle.organization.id;
          negotiationData.sellerLocation.id =
            this.vehicle.allowedLocations[0].id;
          negotiationData.buyerOrganization = this.bid.location.organization;
          negotiationData.buyerLocation = this.bid.location;
          negotiationData.parent = this.negotiationParent;

          await this.$store.dispatch("negotiations/add", negotiationData);
          this.buyerNegotation =
            this.$store.getters["negotiations/negotiation"];
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async addBid(amount) {
      const confirmation = await swal.fire({
        text: this.$t("VEHICLES.CONFRM_BID").replace(
          "{bid}",
          this.$formatCurrency(amount)
        ),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn base-button btn-primary",
        cancelButtonClass: "btn base-button btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      this.loading = true;

      try {
        const bidData = {
          type: "bids",
          bid: amount,
          max_bid: null,
          relationshipNames: ["vehicle", "user", "location"],
          vehicle: this.vehicle,
          user: this.bid.user,
          location: this.bid.location,
          isAuctionEnded: true,
        };

        await this.$store.dispatch("bids/add", bidData);
        this.customBidId = this.$store.getters["bids/bid"].id;

        this.$notify({
          type: "success",
          message: this.$t("VEHICLES.BID_ADDED"),
        });

        this.get();
      } catch (error) {
        let message = this.$t("ERRORS.SOMETHING_WENT_WRONG");
        if (error.response?.status == 400) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message,
        });
        this.formErrors = error.response.data.errors;
      }
      this.loading = false;
    },
    async closeSale(amount) {
      const text = this.$t("VEHICLES.CONFRM_CLOSE_SALE")
        .replace("{vehicle}", this.$objectDenomination(this.vehicle))
        .replace("{price}", this.$formatCurrency(amount ?? this.bid.bid))
        .replace("{buyer}", this.$objectDenomination(this.bid.user));

      const confirmation = await swal.fire({
        text: text,
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn base-button btn-primary",
        cancelButtonClass: "btn base-button btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      this.loading = true;

      try {
        let bidToSend = this.bid;

        if (amount) {
          const bidData = {
            type: "bids",
            bid: amount,
            max_bid: null,
            relationshipNames: ["vehicle", "user", "location"],
            vehicle: this.vehicle,
            user: this.bid.user,
            location: this.bid.location,
            isAuctionEnded: true,
          };

          await this.$store.dispatch("bids/add", bidData);
          bidToSend = this.$store.getters["bids/bid"];
        }

        await this.$store.dispatch("negotiations/closeSale", {
          id: this.buyerNegotation.id,
          bid_id: bidToSend.id,
          amount: amount ?? this.bid.bid,
        });

        this.$notify({
          type: "success",
          message: this.$t("NEGOTIATIONS.NEGOTIATION_SALE_CLOSED"),
        });

        this.$emit("closeVehicleModal", true);
      } catch (error) {
        console.log("error", error);
        let message = this.$t("ERRORS.SOMETHING_WENT_WRONG");

        if (error.response?.status == 400) {
          message = error.response.data.errors[0].detail;
        }

        this.$notify({
          type: "danger",
          message,
        });

        this.formErrors = error.response?.data?.errors ?? [];
      }

      this.loading = false;
    },
  },
};
</script>
