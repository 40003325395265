import { NEGOTIATION_STATUS_PENDING } from "@/constants/negotiations";

export default {
  type: "negotiations",
  status: NEGOTIATION_STATUS_PENDING,
  amount: null,
  discution_type: null,
  relationshipNames: [
    "vehicle",
    "sellerOrganization",
    "sellerLocation",
    "buyerOrganization",
    "buyerLocation",
    "parent",
  ],
  vehicle: {
    type: "vehicles",
    id: null,
  },
  sellerOrganization: {
    type: "organizations",
    id: null,
  },
  sellerLocation: {
    type: "locations",
    id: null,
  },
  buyerOrganization: null,
  buyerLocation: null,
  parent: null,
};
