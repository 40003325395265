<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!vehicle || !sellerNegotiation">
      <span class="loader"></span>
    </span>

    <div v-if="vehicle && sellerNegotiation" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="vehicle"
      >
        <tab-pane title="vehicle" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <vehicle-view-global
            :vehicle="vehicle"
            @onNegotiateVehicle="negotiateVehicle"
          />
        </tab-pane>

        <tab-pane title="seller-negotiation" id="2">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("NEGOTIATIONS.NEGOTIATE_WITH_SELLER") }}
          </span>
          <negotiate-with-seller
            :vehicle="vehicle"
            :negotiation="sellerNegotiation"
          />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="vehicle" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <vehicle-view-logs :vehicle="vehicle" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import VehicleViewGlobal from "../partials/VehicleViewGlobal.vue";
import NegotiateWithSeller from "../partials/NegotiateWithSeller.vue";
import VehicleViewLogs from "../../VehicleManagement/partials/VehicleViewLogs.vue";
import {
  NEGOTIATION_STATUS_PENDING,
  DISCUTION_TYPE_SELLER,
} from "@/constants/negotiations";
import defaultNegotiation from "../defaultNegotiation";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ListFileComponent,
    VehicleViewGlobal,
    NegotiateWithSeller,
    VehicleViewLogs,
  },

  mixins: [],

  props: {
    vehicleId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      vehicle: null,
      sellerNegotiation: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("vehicles/get", this.vehicleId);
        this.vehicle = this.$store.getters["vehicles/vehicle"];

        let params = {
          filter: {
            vehicle_id: this.vehicleId,
            seller_organization_id: this.vehicle.organization.id,
            seller_location_id: this.vehicle.allowedLocations[0].id,
            status: NEGOTIATION_STATUS_PENDING,
            discution_type: DISCUTION_TYPE_SELLER,
          },
          include: "sellerOrganization",
        };

        await this.$store.dispatch("negotiations/list", params);
        const negotiations = this.$store.getters["negotiations/list"];

        if (negotiations.length > 0) {
          this.sellerNegotiation = negotiations[0];
        } else {
          const negotiationData = cloneDeep(defaultNegotiation);

          negotiationData.discution_type = DISCUTION_TYPE_SELLER;
          negotiationData.vehicle.id = this.vehicle.id;
          negotiationData.sellerOrganization.id = this.vehicle.organization.id;
          negotiationData.sellerLocation.id =
            this.vehicle.allowedLocations[0].id;
          // negotiationData.buyerOrganization.id =
          //   this.bid.location.organization.id;
          // negotiationData.buyerLocation.id = this.bid.location.id;

          await this.$store.dispatch("negotiations/add", negotiationData);
          this.sellerNegotiation =
            this.$store.getters["negotiations/negotiation"];
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    negotiateVehicle(bid) {
      this.$emit(
        "onNegotiateVehicle",
        this.vehicle,
        this.sellerNegotiation,
        bid
      );
    },
  },
};
</script>
