export const QUERY_ACTIONS_NEGOTIATE = "negotiate";

export const NEGOTIATION_STATUS_PENDING = "PENDING";

export const NEGOTIATION_STATUS_FAILED = "FAILED";

export const NEGOTIATION_STATUS_SUCCEEDED = "SUCCEEDED";

export const NEGOTIATION_STATUS = [
  NEGOTIATION_STATUS_PENDING,
  NEGOTIATION_STATUS_FAILED,
  NEGOTIATION_STATUS_SUCCEEDED,
];

export const DISCUTION_TYPE_SELLER = "SELLER";

export const DISCUTION_TYPE_BUYER = "BUYER";

export const DISCUTION_TYPE_SELLER_AND_BUYER = "SELLER_AND_BUYER";

export const DISCTUTION_TYPES = [
  DISCUTION_TYPE_SELLER,
  DISCUTION_TYPE_BUYER,
  DISCUTION_TYPE_SELLER_AND_BUYER,
];
