<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("NEGOTIATIONS.NEGOTIATIONS_LIST") }}</h3>
          </div>

          <div class="col-6 text-right">
            <base-button class="elite-button add" icon size="sm">
              <!-- <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span> -->
              <span class="btn-inner--text">
                {{ $t("COMMON.EXPORT") }}
              </span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_NEGOTIATIONS)"
              :objectType="'vehicles'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <negotiation-list-table
        @onViewVehicle="openVehicleViewModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'NEGOTIATION_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("VEHICLES.AUCTION_DETAILS") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openVehicle"
                :objectType="'vehicles'"
                :objectId="openVehicle.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeVehicleModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-vehicle-component
              v-if="openVehicle"
              :vehicleId="openVehicle.id"
              @onNegotiateVehicle="openVehicleNegotiateModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isNegotiateVehicleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isNegotiateVehicleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'NEGOTIATION_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("NEGOTIATIONS.NEGOTIATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openVehicle"
                :objectType="'vehicles'"
                :objectId="openVehicle.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button
                class="close"
                @click="openVehicleViewModal(openVehicle, true)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <negotiate-vehicle-component
              v-if="openVehicle && openNegotiationParent && openBid"
              :vehicleId="openVehicle.id"
              :negotiationParentId="openNegotiationParent.id"
              :bidId="openBid.id"
              @closeVehicleModal="closeVehicleModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import { QUERY_ACTIONS_NEGOTIATE } from "@/constants/negotiations";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import NegotiationListTable from "./partials/NegotiationListTable.vue";
import ViewVehicleComponent from "./components/ViewVehicleComponent.vue";
import NegotiateVehicleComponent from "./components/NegotiateVehicleComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    NegotiationListTable,
    NotificationSubscription,
    ViewVehicleComponent,
    NegotiateVehicleComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const vehicleId = this.$route.query.vehicle;
    const negotiationParentId = this.$route.query.negotiation;
    const bidId = this.$route.query.bid;

    const action = this.$route.query.action;

    let isViewVehicleModalOpened = false;
    let isNegotiateVehicleModalOpened = false;

    let openVehicle = null;
    let openNegotiationParent = null;
    let openBid = null;

    if (vehicleId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewVehicleModalOpened = true;
      } else if (
        action === QUERY_ACTIONS_NEGOTIATE &&
        negotiationParentId &&
        bidId
      ) {
        isNegotiateVehicleModalOpened = true;

        openNegotiationParent = { id: negotiationParentId };
        openBid = { id: bidId };
      }

      openVehicle = { id: vehicleId };
    }

    console.log("openVehicle", openVehicle);
    console.log("openNegotiationParent", openNegotiationParent);
    console.log("openBid", openBid);
    console.log("action", action);
    console.log("isViewVehicleModalOpened", isViewVehicleModalOpened);
    console.log("isNegotiateVehicleModalOpened", isNegotiateVehicleModalOpened);

    return {
      isViewVehicleModalOpened: isViewVehicleModalOpened,
      isNegotiateVehicleModalOpened: isNegotiateVehicleModalOpened,
      openVehicle: openVehicle,
      openNegotiationParent: null,
      openBid: openBid,
      renderKey: 1,
    };
  },

  methods: {
    openVehicleViewModal(vehicle, reRender = false) {
      this.closeVehicleModal();
      this.openVehicle = vehicle;
      this.isViewVehicleModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Negotiations",
          query: { vehicle: this.openVehicle.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openVehicleNegotiateModal(vehicle, negotiationParent, bid) {
      this.closeVehicleModal();
      this.openVehicle = vehicle;
      this.openNegotiationParent = negotiationParent;
      this.openBid = bid;
      this.isNegotiateVehicleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Negotiations",
          query: {
            vehicle: this.openVehicle.id,
            negotiation: this.openNegotiationParent.id,
            bid: this.openBid.id,
            action: QUERY_ACTIONS_NEGOTIATE,
          },
        }).href
      );
    },
    closeVehicleModal(reRender = false) {
      this.isViewVehicleModalOpened = false;
      this.isNegotiateVehicleModalOpened = false;
      this.openVehicle = null;
      this.openNegotiationParent = null;
      this.openBid = null;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Negotiations",
          query: {},
        }).href
      );
    },
  },
};
</script>
